@import styles/sass/abstracts

.layout
  +res-lg-min
    display: flex
    gap: 4rem
    min-height: 62.2rem
    > *
      width: calc(50% - 2rem)

.imageBox
  +res-md-max
    display: none

.main
  padding: 4rem $wcont-padding 4rem 0
  +vSpace(1.6rem)
  +res-md-max
    padding: 2rem $wcont-padding

.tabs
  display: flex
  > *
    width: 50%

.tab
  border: 1px solid $stroke-color
  text-align: center
  +typoTextLg
  padding: 1.2rem 2rem
  +res-sm-max
    padding: 1rem
  &:not(:disabled)
    text-decoration: underline
  &:disabled
    background: $fill-black
    color: $text-white
    font-weight: $fw-semibold

.tabs, .tabPanels
  +res-md-min
    max-width: 51.6rem
  +res-md-only
    margin-left: auto
    margin-right: auto

.tabPanel
  +vSpace(1.6rem)
  +res-md-min
    padding: 3.2rem
    border: 1px solid $stroke-color
  &:not(.tabPanel--active)
    display: none

.tabPanelTitle
  +typoH5
  text-align: center
  +res-sm-max
    +typoH4Mobile

.tabPanelDescription
  text-align: center
  +typoTextSm
  +customContent

.form, .form2
  +vSpace(1.6rem)

.actions
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: 1.6rem

.forgot
  +res-xs-max
    order: 999
    margin: auto

.cta
  width: 100%

.social
  border-top: 1px solid $stroke-color
  padding-top: 1.6rem
  +vSpace(1.6rem)

.socialTitle
  +typoTextSm
  text-align: center

.socialList
  display: flex
  flex-wrap: wrap
  gap: 1.6rem
  > *
    flex: 1
    white-space: nowrap

.formError
  +typoTextSm
  margin-bottom: 1.6rem
  color: $text-error
